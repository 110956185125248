<template>
  <div id="app">
    <transition name="fade" mode="out-in">
		<router-view class="view w640"></router-view>
	</transition>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
<style lang="scss">
	@import "assets/base_pc.scss";
</style>
