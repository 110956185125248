/* 本地Storage */
const setSS = function (key, info) {
	return window.sessionStorage.setItem(key, JSON.stringify(info));
}
const getSS = function (key, _default) {
	_default = typeof _default !== "undefined" ? _default : "{}";
	var obj = window.sessionStorage.getItem(key);
	try {
		obj = JSON.parse(obj);
	} catch (e) {
		obj = null;
	}
	return obj || _default;
}
const setLS = function (key, info) {
	return window.localStorage.setItem(key, JSON.stringify(info));
}
const getLS = function (key, _default) {
	_default = typeof _default !== "undefined" ? _default : "{}";
	var obj = window.localStorage.getItem(key);
	try {
		obj = JSON.parse(obj);
	} catch (e) {
		obj = null;
	}
	return obj || _default;
}


export default {
	install(Vue) {
		Vue.prototype.$setSS = setSS
		Vue.prototype.$getSS = getSS
		Vue.prototype.$getLS = getLS
		Vue.prototype.$setLS = setLS
	}
}