import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import Index from '@/views/index/Index.vue'
const router = new Router({
	mode: 'history', //配置路由模式
	base: __dirname, //配置应用根路径
	scrollBehavior: () => ({
		y: 0
	}),
	routes: [
	{
		name: 'index',
		path: '/',
		component: Index
	},
	{
		path: '*',
		redirect: '/'
	}
	]
})
export default router