<template>
	<div class="page-cont">
		<header id="block1">
			<div class="content fx fx-x-between fx-y-center">
				<div class="logo-cont"></div>
				<div>
					<ul class="fx fx-y-center">
						<li @click='scrollIndex(0,"block1")'>Home</li>
						<li @click='scrollIndex(1,"block2")'>MMaaS</li>
						<li @click='scrollIndex(2,"block3")'>Arbitrage</li>
						<li @click='scrollIndex(3,"block4")'>Partners</li>
						<li @click='scrollIndex(4,"block5")'>FAQ</li>
						<li @click='scrollIndex(5,"block6")'>Contact Us</li>
					</ul>
				</div>
			</div>
		</header>
		<section  class="banner-cont" ref="bannerCont" >
			<div class="w1400">
				<h1 class="text-gradient">MakerHub</h1>
				<h2>Web3.0 Project liquidity provider</h2>
				<h3 class="c-w-m">Provide Market-Making as a Service and quantitative arbitrage services for Web3 projects</h3>
				<div>
					<ul class="fx fx-y-center">
						<li class="fx fx-y-center">
							<div class="icon fx-align"><i><svg width="100%" height="100%" viewBox="0 0 11 9" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M10.6371 0.229234C11.0628 0.581086 11.1227 1.21141 10.7708 1.63711L4.98512 8.63711C4.79249 8.87017 4.50475 9.00356 4.20241 8.99996C3.90006 8.99635 3.61559 8.85614 3.42857 8.61856L0.21428 4.53522C-0.127324 4.10126 -0.0524517 3.47254 0.381512 3.13093C0.815477 2.78933 1.4442 2.8642 1.7858 3.29816L4.23331 6.40741L9.22925 0.362943C9.5811 -0.0627551 10.2114 -0.122619 10.6371 0.229234Z"
											fill="currentColor"></path>
									</svg></i></div>Fully Transparent
						</li>
						<li class="fx fx-y-center">
							<div class="icon fx-align"><i><svg width="100%" height="100%" viewBox="0 0 11 9" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M10.6371 0.229234C11.0628 0.581086 11.1227 1.21141 10.7708 1.63711L4.98512 8.63711C4.79249 8.87017 4.50475 9.00356 4.20241 8.99996C3.90006 8.99635 3.61559 8.85614 3.42857 8.61856L0.21428 4.53522C-0.127324 4.10126 -0.0524517 3.47254 0.381512 3.13093C0.815477 2.78933 1.4442 2.8642 1.7858 3.29816L4.23331 6.40741L9.22925 0.362943C9.5811 -0.0627551 10.2114 -0.122619 10.6371 0.229234Z"
											fill="currentColor"></path>
									</svg></i></div>Active collaboration
						</li>
						<li class="fx fx-y-center">
							<div class="icon fx-align"><i><svg width="100%" height="100%" viewBox="0 0 11 9" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M10.6371 0.229234C11.0628 0.581086 11.1227 1.21141 10.7708 1.63711L4.98512 8.63711C4.79249 8.87017 4.50475 9.00356 4.20241 8.99996C3.90006 8.99635 3.61559 8.85614 3.42857 8.61856L0.21428 4.53522C-0.127324 4.10126 -0.0524517 3.47254 0.381512 3.13093C0.815477 2.78933 1.4442 2.8642 1.7858 3.29816L4.23331 6.40741L9.22925 0.362943C9.5811 -0.0627551 10.2114 -0.122619 10.6371 0.229234Z"
											fill="currentColor"></path>
									</svg></i></div>Multi-chain collaboration
						</li>
						<li class="fx fx-y-center">
							<div class="icon fx-align"><i><svg width="100%" height="100%" viewBox="0 0 11 9" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M10.6371 0.229234C11.0628 0.581086 11.1227 1.21141 10.7708 1.63711L4.98512 8.63711C4.79249 8.87017 4.50475 9.00356 4.20241 8.99996C3.90006 8.99635 3.61559 8.85614 3.42857 8.61856L0.21428 4.53522C-0.127324 4.10126 -0.0524517 3.47254 0.381512 3.13093C0.815477 2.78933 1.4442 2.8642 1.7858 3.29816L4.23331 6.40741L9.22925 0.362943C9.5811 -0.0627551 10.2114 -0.122619 10.6371 0.229234Z"
											fill="currentColor"></path>
									</svg></i></div>Increased revenue
						</li>
					</ul>
				</div>
			</div>
			<img class="banner-img" src="../../assets/img/banner.png" alt="" srcset="">
		</section>
		<section class="logos-list-cont">
			<div class="content">
				<ul class="fx fx-y-center">
					<li class="fx-align"><img src="../../assets/img/lb_logos/BitFinex.svg" alt=""></li>
					<li class="fx-align"><img src="../../assets/img/lb_logos/coinstore.png" alt=""></li>
					<li class="fx-align"><img src="../../assets/img/lb_logos/Binance.svg" alt=""></li>
					<li class="fx-align"><img src="../../assets/img/lb_logos/Huobi.png" alt=""></li>
					<li class="fx-align"><img src="../../assets/img/lb_logos/MEXC.svg" alt=""></li>
					<li class="fx-align"><img src="../../assets/img/lb_logos/BitMart.svg" alt=""></li>
					<li class="fx-align"><img src="../../assets/img/lb_logos/GateIO.svg" alt=""></li>
					<li class="fx-align"><img src="../../assets/img/lb_logos/Kraken.svg" alt=""></li>
					<li class="fx-align"><img src="../../assets/img/lb_logos/LBank.png" alt=""></li>
					<li class="fx-align"><img src="../../assets/img/lb_logos/bybit.png" alt=""></li>
					<li class="fx-align"><img src="../../assets/img/lb_logos/hitbtc.png" alt=""></li>
				</ul>
			</div>
		</section>
		<section id="block2" class="info-wrapper info-wrapper-01" ref="infoBoosts" :class="{ 'active': isShowBoosts }">
			<div class="content">
				<h3 class="title-cont">How market making <span class="text-gradient">boosts</span> your <span class="text-gradient">adoption</span></h3>
				<div class="text-center c-w-m f-20 sub-title">Provide security, transparency and top-level liquidity for Web3.0 projects</div>
				<div class="tab-cont-list fx-align">
					<div @click="tabbar = 0" :class="{'on': tabbar === 0}">Before</div>
					<div @click="tabbar = 1" :class="{'on': tabbar === 1}">After</div>
				</div>
				<div class="info-cont">
					<ul class="fx">
						<li>
							<div class="img-cont" v-if="tabbar === 0"><img src="../../assets/img/svg_01_b.svg" alt="" srcset=""></div>
							<div class="img-cont" v-if="tabbar === 1"><img src="../../assets/img/svg_01.svg" alt="" srcset=""></div>
							<h4>Boost trading volume</h4>
							<p>With <span class="f-b">healthy markets</span> and reduced volatility.</p>
						</li>
						<li>
							<div class="img-cont" v-if="tabbar === 0"><img src="../../assets/img/svg_02_b.svg" alt="" srcset=""></div>
							<div class="img-cont" v-if="tabbar === 1"><img src="../../assets/img/svg_02.svg" alt="" srcset=""></div>
							<h4>Attract investors</h4>
							<p>With <span class="f-b">order book depth</span>, allowing investors to execute orders of larger sizes.</p>
						</li>
						<li>
							<div class="img-cont" v-if="tabbar === 0"><img src="../../assets/img/svg_03_b.svg" alt="" srcset=""></div>
							<div class="img-cont" v-if="tabbar === 1"><img src="../../assets/img/svg_03.svg" alt="" srcset=""></div>
							<h4>Increase adoption</h4>
							<p>With <span class="f-b">tight spreads</span> & cross-exchange price stability.</p>
						</li>
					</ul>
				</div>
			</div>
		</section>
		<section id="block3" class="info-wrapper info-wrapper-06" ref="infoArbitrage" :class="{ 'active': isShowArbitrage }">
			<div class="content">
				<h3 class="title-cont">Get the best <span class="text-gradient">arbitrage services</span> </h3>
				<div class="text-center c-w-m f-20 sub-title">Increase profits for web3.0 projects through CEX&DEX price difference arbitrage</div>
				<div class="info-cont fx fx-y-center fx-x-between">
					<div class="left-cont">
						<div class="img-cont-l"><img src="../../assets/img/logos_l.png" alt="" srcset=""></div>
						<div class="text-center p-t-30">
							<h3>Multi-chain DEXs</h3>
							<p class="c-w-m p-t-10">20+ Blockchains Supported</p>
						</div>
					</div>
					<h2 class="text-gradient MMaaS">MMaaS</h2>
					<div class="right-cont">
						<div class="img-cont-l"><img src="../../assets/img/logos_r.png" alt="" srcset=""></div>
						<div>
							<h3 class="text-center p-t-30">20 Exchanges Supported</h3>
							<p class="c-w-m p-t-10 opacity">20</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- <section class="info-wrapper info-wrapper-02" ref="infoTrust" :class="{ 'active': isShowTrust }">
			<div class="content">
				<h3 class="title-cont"><span class="text-gradient">Don't trust</span>, verify your market maker!</h3>
				<div class="sub-title text-center c-w-m f-20 p-b-20">With our real-time and historic dashboard</div>
				<div class="fx fx-y-center">
					<div class="img-cont fx-shrink"></div>
					<div>
						<ul>
							<li>
								<h4 class="fx fx-y-center"><i><svg width="100%" height="100%" viewBox="0 0 11 9" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M10.6371 0.229234C11.0628 0.581086 11.1227 1.21141 10.7708 1.63711L4.98512 8.63711C4.79249 8.87017 4.50475 9.00356 4.20241 8.99996C3.90006 8.99635 3.61559 8.85614 3.42857 8.61856L0.21428 4.53522C-0.127324 4.10126 -0.0524517 3.47254 0.381512 3.13093C0.815477 2.78933 1.4442 2.8642 1.7858 3.29816L4.23331 6.40741L9.22925 0.362943C9.5811 -0.0627551 10.2114 -0.122619 10.6371 0.229234Z"
											fill="currentColor"></path>
									</svg></i> Verifiable results</h4>
								<div>No words, but actions. See the exact difference liquidity can make using our real-time dashboards.
								</div>
							</li>
							<li>
								<h4 class="fx fx-y-center"><i><svg width="100%" height="100%" viewBox="0 0 11 9" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M10.6371 0.229234C11.0628 0.581086 11.1227 1.21141 10.7708 1.63711L4.98512 8.63711C4.79249 8.87017 4.50475 9.00356 4.20241 8.99996C3.90006 8.99635 3.61559 8.85614 3.42857 8.61856L0.21428 4.53522C-0.127324 4.10126 -0.0524517 3.47254 0.381512 3.13093C0.815477 2.78933 1.4442 2.8642 1.7858 3.29816L4.23331 6.40741L9.22925 0.362943C9.5811 -0.0627551 10.2114 -0.122619 10.6371 0.229234Z"
											fill="currentColor"></path>
									</svg></i> Valuable insights</h4>
								<div>Gain valuable adoption insights and make better decisions that go beyond trading volume.</div>
							</li>
							<li>
								<h4 class="fx fx-y-center"><i><svg width="100%" height="100%" viewBox="0 0 11 9" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M10.6371 0.229234C11.0628 0.581086 11.1227 1.21141 10.7708 1.63711L4.98512 8.63711C4.79249 8.87017 4.50475 9.00356 4.20241 8.99996C3.90006 8.99635 3.61559 8.85614 3.42857 8.61856L0.21428 4.53522C-0.127324 4.10126 -0.0524517 3.47254 0.381512 3.13093C0.815477 2.78933 1.4442 2.8642 1.7858 3.29816L4.23331 6.40741L9.22925 0.362943C9.5811 -0.0627551 10.2114 -0.122619 10.6371 0.229234Z"
											fill="currentColor"></path>
									</svg></i> Non-custodial</h4>
								<div>We do not hold your funds. We connect our system to your trading accounts via a trade-only API key.
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section> -->
		<!-- <section class="info-wrapper info-wrapper-03" ref="infoPartnership" :class="{ 'active': isShowPartnership }">
			<div class="content">
				<h3 class="title-cont">Market making as an <span class="text-gradient">active partnership</span></h3>
				<div class="sub-title text-center c-w-m f-20 p-b-20">With our real-time and historic dashboard</div>
				<div>
						<ul>
							<li class="text-center">
								<h4 class="fx-align">Verifiable results</h4>
								<div>No words, but actions. See the exact difference liquidity can make using our real-time dashboards.
								</div>
							</li>
							<li class="text-center">
								<h4 class="fx-align">Valuable insights</h4>
								<div>Gain valuable adoption insights and make better decisions that go beyond trading volume.</div>
							</li>
							<li class="text-center">
								<h4 class="fx-align">Non-custodial</h4>
								<div>We do not hold your funds. We connect our system to your trading accounts via a trade-only API key.
								</div>
							</li>
						</ul>
				</div>
			</div>
		</section> -->
		<section id="block4" class="info-wrapper info-wrapper-04" ref="infoOfficialPartners" :class="{ 'active': isShowOfficialPartners }">
			<div class="content">
				<h3 class="title-cont">Our & <span class="text-gradient">Your Official Partners</span></h3>
				<div class="sub-title text-center c-w-m f-20 p-b-20">The ultimate Web3.0 partnership ecosystem</div>
				<div>
					<ul class="fx fx-wrap">
						<li>
							<div class="logo-img-cont">
								<img src="../../assets/img/partners_logos/01.png" srcset="">
							</div>
							<!-- <h6>NULS</h6> -->
						</li>
						<li>
							<div class="logo-img-cont">
								<img src="../../assets/img/partners_logos/02.png" srcset="">
							</div>
							<!-- <h6>Binance</h6> -->
						</li>
						<li>
							<div class="logo-img-cont">
								<img src="../../assets/img/partners_logos/03.png" srcset="">
							</div>
							<!-- <h6>NERVE</h6> -->
						</li>
						<li>
							<div class="logo-img-cont">
								<img src="../../assets/img/partners_logos/04.png" srcset="">
							</div>
							<!-- <h6>NABOX</h6> -->
						</li>
						<li>
							<div class="logo-img-cont">
								<img src="../../assets/img/partners_logos/05.png" srcset="">
							</div>
							<!-- <h6>Bridging services</h6> -->
						</li>
						<li>
							<div class="logo-img-cont">
								<img src="../../assets/img/partners_logos/06.png" srcset="">
							</div>
							<!-- <h6>Bridging services</h6> -->
						</li>
						<li>
							<div class="logo-img-cont">
								<img src="../../assets/img/partners_logos/07.png" srcset="">
							</div>
							<!-- <h6>Bridging services</h6> -->
						</li>
						<li>
							<div class="logo-img-cont">
								<img src="../../assets/img/partners_logos/08.png" srcset="">
							</div>
							<!-- <h6>Bridging services</h6> -->
						</li>
						<li>
							<div class="logo-img-cont">
								<img src="../../assets/img/partners_logos/09.png" srcset="">
							</div>
							<!-- <h6>Bridging services</h6> -->
						</li>
						<li>
							<div class="logo-img-cont">
								<img src="../../assets/img/partners_logos/10.png" srcset="">
							</div>
							<!-- <h6>Bridging services</h6> -->
						</li>
					<!-- 	<li>
							<div class="logo-img-cont">
								<img src="../../assets/img/partners_logos/11.png" srcset="">
							</div>
							<h6>Bridging services</h6>
						</li> -->
					</ul>
				</div>
			</div>
		</section>
		<section id="block5" class="info-wrapper info-wrapper-07" ref="infoOfficialQuestions" :class="{ 'active': isShowOfficialQuestions }">
			<div class="content">
				<h3 class="title-cont">Frequently asked <span class="text-gradient">questions</span></h3>
				<div class="questions-list">
					<ul>
						<li :class="{ 'open': openIndex === 1 }">
							<h3 @click="openInfo(1)" class="fx fx-y-center fx-x-between"><span>What Is Market Making as a Service?</span><i class="icon-arrow"></i></h3>
							<div class="cont">
								<p>Market Making as a Service (MMaaS) is a technology service that enables token issuers to set their strategies in market making, allowing them to trade and manage their own liquidity.  <a href="https://coinmarketcap.com/academy/glossary/market-making-as-a-service-mmaas" target="_blank">learn more</a></p>
								<!-- <p>The amount of tokens and quote assets depend of your liquidity requirements such as depth.</p> -->
							</div>
						</li>
						<li :class="{ 'open': openIndex === 2 }">
							<h3 @click="openInfo(2)" class="fx fx-y-center fx-x-between"><span>What is the difference between Proprietary MM and MMaaS?</span><i class="icon-arrow"></i></h3>
							<div class="cont">
								<p>Proprietary Market Makers use their own capital and your token to profit based on P&L from your project investors without any transparency over the trading model.</p>
								<p>MMaaS does not hold custody of your token, nor your capital, but rather connects to your exchange accounts and places limit orders.</p>
							</div>
						</li>
						<li  :class="{ 'open': openIndex === 3 }">
							<h3 @click="openInfo(3)" class="fx fx-y-center fx-x-between"><span>Do I need any additional funds?</span><i class="icon-arrow"></i></h3>
							<div class="cont">
								<p>As a MMaaS, we do not hold custody of your assets, therefore you would need to fund the associated exchange accounts with your token and the quote asset.</p>
								<p>The amount of tokens and quote assets depend of your liquidity requirements such as depth.</p>
							</div>
						</li>
						<li  :class="{ 'open': openIndex === 4 }">
							<h3 @click="openInfo(4)" class="fx fx-y-center fx-x-between"><span>Fund security & transparency?</span><i class="icon-arrow"></i></h3>
							<div class="cont">
								<p>We do not hold your funds. We connect our system to your trading accounts via a trade-only API key.</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
		<section id="block6" class="info-wrapper info-wrapper-05" ref="infoBull" :class="{ 'active': isShowBull }">
			<div class="content">
				<h3 class="title-cont">Become <span class="text-gradient">bull market ready</span></h3>
				<div class="sub-title text-center c-w-m f-20 ">MakerHub Transparent MMaaS can take your project</div>
				<!-- <p class="text-center c-w-m f-14">Let's get in touch.</p> -->
				<!-- <p class="text-center c-w-m f-14">Fully Transparent.</p> -->
			</div>
		</section>
		<section class="touch-cont" ref="infoTouch" :class="{ 'active': isShowTouch }">
			<div class="content">
				<h3>Curious to know more?</h3>
				<div class="fx fx-y-center">
					<div class="email-cont fx fx-y-center">
						<i class="icon-email"></i>support@makerhub.vip
					</div>
					<p class="c-w-m m-l-20">Let's get in touch.</p>
				</div>
			</div>
		</section>
		<footer>
			<div class="content">
				<div class=" fx fx-x-between fx-y-center">
					<div class="logo-cont"></div>
					<div>
						<ul class="fx fx-y-center">
							<li @click='scrollIndex(0,"block1")'>Home</li>
							<li @click='scrollIndex(1,"block2")'>MMaaS</li>
							<li @click='scrollIndex(2,"block3")'>Arbitrage</li>
							<li @click='scrollIndex(3,"block4")'>Partners</li>
							<li @click='scrollIndex(4,"block5")'>FAQ</li>
							<li @click='scrollIndex(5,"block6")'>Contact Us</li>
						</ul>
					</div>
				</div>
				<div class="info">MakerHub is a Crypto-Financial service company building a trading infrastructure that provides market-making services to tokenized Web3.0 projects.</div>
				<div class="copyright-cont">Copyright 2023 © All rights Reserved. MakerHub</div>
			</div>
		</footer>
	</div>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill'
	export default {
		name: 'Index',
		data() {
			return {
				tabbar:1,
				isShowBoosts: false,
				isShowTrust: false,
				isShowPartnership: false,
				isShowOfficialPartners:false,
				isShowBull: false,
				isShowTouch: false,
				openIndex: null,
				isShowArbitrage: false,
				isShowOfficialQuestions: false,
				isActive: null,
				block_one: '',
				block_two: '',
				block_three: '',
				bodyHeight: 0,
			}
		},
		watch: {
			bodyHeight: {
				handler(newVal) {
				if (newVal >= this.block_one) {
					this.isActive = 0
				}
				if (newVal >= this.block_two) {
					this.isActive = 1
				}
				if (newVal >= this.block_three) {
					this.isActive = 2
				}
				if (newVal >= this.block_four) {
					this.isActive = 3
				}
				if (newVal >= this.block_five) {
					this.isActive = 4
				}
				if (newVal >= this.block_six) {
					this.isActive = 5
				}
				if (newVal == 0) {
					this.isActive = null
				}
				},
				immediate: true,
				deep: true
			}
			},
		mounted() {
		  const bannerElement = this.$refs.bannerCont; // 获取DOM元素
			setTimeout(() => {
				 bannerElement.classList.add('active'); // 添加class
			}, 100);
			     
			window.addEventListener('scroll', this.handleBoostsScroll); 
			window.addEventListener('scroll', this.handleArbitrageScroll); 
			window.addEventListener('scroll', this.handleQuestionsScroll); 
			window.addEventListener('scroll', this.handleTrustScroll); 
			window.addEventListener('scroll', this.handlePartnershipScroll); 
			window.addEventListener('scroll', this.handleOfficialPartnersScroll); 
			window.addEventListener('scroll', this.handleBull); 
			window.addEventListener('scroll', this.handleTouch); 
		},
		methods: {
			getDomOffsetTop(){
				window.addEventListener("scroll", this.windowScroll);
				//先获取右侧内容区域每个模块的高度
				this.block_one = document.getElementById('block1').offsetTop
				this.block_two = document.getElementById('block2').offsetTop
				this.block_three = document.getElementById('block3').offsetTop
				this.block_four = document.getElementById('block4').offsetTop
				this.block_five = document.getElementById('block5').offsetTop
				this.block_six = document.getElementById('block6').offsetTop
			},
			scrollIndex(val, id) {
				this.isActive = val
				this.$nextTick(() => {
				let targetbox = document.getElementById(id)
				let height = targetbox.offsetTop
				// document.documentElement.scrollTop = height
				//平滑滚动
				if (!('scrollBehavior' in document.documentElement.style)) {
					smoothscroll.polyfill()
				}
				window.scrollTo({
					    top: height,
					    behavior: "smooth"
					});
				})
			},
			windowScroll() {
				this.bodyHeight = document.documentElement.scrollTop || document.body.scrollTop
			},
			/**
			 * 下面是33滑动到对应位置动效
			 */
			handleBoostsScroll() {
				const elementBoosts = this.$refs.infoBoosts; // 获取DOM元素
				const rect = elementBoosts.getBoundingClientRect(); // 获取位置信息
				const top = rect.top; // 获取元素顶部距离视口顶部的距离
				if (top <= 500) {
					this.isShowBoosts = true;
				}
			},
			handleArbitrageScroll() {
				const elementArbitrage = this.$refs.infoArbitrage; // 获取DOM元素
				const rect = elementArbitrage.getBoundingClientRect(); // 获取位置信息
				const top = rect.top; // 获取元素顶部距离视口顶部的距离
				if (top <= 500) {
					this.isShowArbitrage = true;
				}
			},
			handleQuestionsScroll() {
				const elementQuestions = this.$refs.infoOfficialQuestions; // 获取DOM元素
				const rect = elementQuestions.getBoundingClientRect(); // 获取位置信息
				const top = rect.top; // 获取元素顶部距离视口顶部的距离
				if (top <= 500) {
					this.isShowOfficialQuestions = true;
				} 
			},
			handleTrustScroll() {
				const elementTrust = this.$refs.infoTrust; // 获取DOM元素
				const rect = elementTrust.getBoundingClientRect(); // 获取位置信息
				const top = rect.top; // 获取元素顶部距离视口顶部的距离
				if (top <= 500) {
					this.isShowTrust = true;
				} 
			},
			handlePartnershipScroll() {
				const elementPartnership = this.$refs.infoPartnership; // 获取DOM元素
				const rect = elementPartnership.getBoundingClientRect(); // 获取位置信息
				const top = rect.top; // 获取元素顶部距离视口顶部的距离
				if (top <= 500) {
					this.isShowPartnership = true;
				} 
			},
			handleOfficialPartnersScroll() {
				const elementOfficialPartners = this.$refs.infoOfficialPartners; // 获取DOM元素
				const rect = elementOfficialPartners.getBoundingClientRect(); // 获取位置信息
				const top = rect.top; // 获取元素顶部距离视口顶部的距离
				if (top <= 500) {
					this.isShowOfficialPartners = true;
				} 
			},
			handleBull() {
				const elementBull = this.$refs.infoBull; // 获取DOM元素
				const rect = elementBull.getBoundingClientRect(); // 获取位置信息
				const top = rect.top; // 获取元素顶部距离视口顶部的距离
				if (top <= 500) {
					this.isShowBull = true;
				} 
			},
			handleTouch() {
				const elementTouch = this.$refs.infoTouch; // 获取DOM元素
				const rect = elementTouch.getBoundingClientRect(); // 获取位置信息
				const top = rect.top; // 获取元素顶部距离视口顶部的距离
				if (top <= 700) {
					this.isShowTouch = true;
				}
			},
			openInfo(index){
				if(this.openIndex === index){
					this.openIndex = null
				} else{
					this.openIndex = index;
				}
			}
			
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleBoostsScroll); 
			window.removeEventListener('scroll', this.handleArbitrageScroll); 
			window.removeEventListener('scroll', this.handleQuestionsScroll); 
			window.removeEventListener('scroll', this.handleTrustScroll); 
			window.removeEventListener('scroll', this.handlePartnershipScroll); 
			window.removeEventListener('scroll', this.handleOfficialPartnersScroll); 
			window.removeEventListener('scroll', this.handleBull); 
			window.removeEventListener('scroll', this.handleTouch); 
		}
	}
</script>

<style scoped lang="scss">
	@import "index.scss";
</style>